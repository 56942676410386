<template>
  <div
    id="cgu_cristal_web_de"
    class="text-regular"
  >
    <div class="bloc">
      <h2 class="s4 light">
        1: Geltungsbereich
      </h2>
      <p>
        Die Buchhandlung LDE GmbH & Co. KG (im Folgenden: LDE) liefert Schulbücher und andere Lehrmittel und erbringt
        Dienstleistungen insbesondere im Bereich der Schulbuch-/Lehrmittelverwaltung und der digitalen Lehrmittel.
      </p>
      <p>
        Für die Geschäftsbeziehung zwischen der LDE und ihren Kunden sowie für die Nutzung der von LDE angebotenen
        digitalen Dienstleistungen gelten ausschließlich – auch für alle zukünftigen Geschäfte – die nachfolgenden
        llgemeinen Geschäftsbedingungen in ihrer zum Zeitpunkt des Vertragsschlusses bzw. der Nutzung gültigen Fassung.
        Abweichende Bedingungen werden nicht anerkannt, es sei denn, LDE hat ausdrücklich ihrer Geltung zugestimmt.
      </p>
    </div>

    <div class="bloc">
      <h2 class="s4 light">
        2: Verkauf von Waren, insbesondere Schulbüchern und anderen Lehrmitteln
      </h2>

      <h3 class="s6">
        A) Vertragsschluss
      </h3>
      <p>
        Die Angebote von LDE sind freibleibend. Der Kunde gibt, indem er über Internet, E-Mail, Telefon, Fax oder über
        sonstige Kommunikationswege eine Bestellung an LDE sendet, ein verbindliches Angebot auf Abschluss eines
        Kaufvertrages mit. Der Kunde erhält per E-Mail oder Fax eine Eingangsbestätigung mit den Bestelldaten. Diese
        Eingangsbestätigung stellt keine Annahme des Angebots dar, sondern dient der Information des Kunden über den
        Eingang seiner Bestellung bei LDE. Der Vertrag kommt zustande, wenn LDE die Bestellung durch Versand der
        bestellten Ware oder durch ausdrückliche Versandbestätigung mit einer zweiten E-Mail annimmt. Über Waren, die
        nicht in der Versandbestätigung aufgeführt sind, kommt kein Kaufvertrag zustande. Kann LDE das Angebot des
        Kunden nicht annehmen, wird der Kunde über die Nichtverfügbarkeit informiert. Bereits erbrachte Gegenleistungen
        des Kunden werden unverzüglich erstattet.
      </p>

      <h3 class="s6">
        B) Nichtverfügbarkeit
      </h3>
      <p>
        Wir behalten uns vor, von einer Ausführung Ihrer Bestellung abzusehen, wenn wir den bestellten Titel nicht
        vorrätig haben, der nicht vorrätige Titel beim Verlag vergriffen und die bestellte Ware infolgedessen nicht
        verfügbar ist. In diesem Fall werden wir Sie unverzüglich über die Nichtverfügbarkeit informieren und einen
        gegebenenfalls von Ihnen bereits gezahlten Kaufpreis unverzüglich rückerstatten.
      </p>

      <h3 class="s6">
        C) Lieferung
      </h3>
      <p>
        Die Lieferung erfolgt entsprechend der Bestellung des Kunden an die von ihm benannte Lieferadresse oder, wenn
        der Kunde den Schulbuch-Tütenservice nutzt, an die von ihm angegebene Schule. In diesem Fall erfolgt die
        Lieferung zu dem mit der Schule vereinbarten Termin. Dem Kunden genannte Liefertermine gelten als Richtwerte.
        Bestellungen von Schulbüchern innerhalb der Schulbuchsaison (sechs Wochen vor Ende des alten Schuljahres bis
        unmittelbar vor Beginn des neuen Schuljahres) werden in aller Regel rechtzeitig zum Schulbeginn des jeweiligen
        Bundeslandes ausgeliefert. Hat der LDE die Lieferungsverzögerungen nicht zu vertreten, verlängert sich die
        Lieferfrist um den zur Beseitigung des Lieferhindernisses notwendigen Zeitraum. LDE ist zu Teillieferung
        berechtigt, soweit dies für Sie zumutbar ist.
      </p>

      <h3 class="s6">
        D) Preise und Versandkosten
      </h3>
      <p>
        Alle Preise sind Endpreise in Euro. Sie enthalten die gesetzliche Mehrwertsteuer. Die Lieferung erfolgt
        versandkostenfrei.
      </p>

      <h3 class="s6">
        E) Zahlungsbedingungen, Verzug
      </h3>
      <p>
        Wir bieten Zahlung per Vorkasse (Überweisung, PayPal) sowie Zahlung auf Rechnung. Der Kaufpreis ist bei
        Lieferung gegen Rechnung innerhalb von 30 Tagen nach Rechnungsstellung fällig. Skontoabzüge werden nicht
        anerkannt und werden nachgefordert.
      </p>
      <p>
        Kommt der Besteller in Zahlungsverzug, so ist LDE berechtigt, Verzugszinsen gegenüber Verbrauchern in Höhe von
        jährlich 5% über dem von der Europäischen Zentralbank bekanntgegebenen Basiszinssatz zu fordern. Bei
        Rechtsgeschäften, an denen ein Verbraucher nicht beteiligt ist, beträgt der jährliche Zinssatz für
        Entgeltforderungen neun Prozentpunkte über dem von der Europäischen Zentralbank bekanntgegebenen Basiszinssatz.
      </p>

      <h3 class="s6">
        F) Eigentumsvorbehalt
      </h3>
      <p>
        Bis zur vollständigen Erfüllung der Kaufpreisforderung durch den Besteller verbleibt die gelieferte Ware im
        Eigentum des Verkäufers.
      </p>

      <h3 class="s6">
        G) Gewährleistung
      </h3>
      <p>Liegt ein Mangel der Kaufsache vor, gelten die gesetzlichen Gewährleistungsvorschriften.</p>

      <h3 class="s6">
        H) Widerrufsrecht
      </h3>
      <p>
        Verbrauchern steht ein gesetzliches Widerrufsrecht gemäß der Widerrufsbelehrung auf unserer Internetseite
        <a
          :href="`${urlExtranetDe}/widerrufsbelehrung-fuer-verbaucher/`"
          target="_blank"
          rel="noreferrer noopener"
        >
          {{ urlExtranetDe }}widerrufsbelehrung-fuer-verbaucher/
        </a>
        zu. Verbraucher ist jede natürliche Person, die ein Rechtsgeschäft zu Zwecken abschließt, die überwiegend
        weder ihrer gewerblichen noch ihrer selbständigen beruflichen Tätigkeit zugerechnet werden können (§ 13 BGB).
      </p>

      <h3 class="s6">
        I) Rückgaberecht
      </h3>
      <p>
        LDE gewährt Kunden, die keine Verbraucher i.S.d. § 13 BGB sind, ein freiwilliges Rückgaberecht für Schulbücher,
        sofern diese innerhalb von 14 Tagen ab Lieferdatum in wiederverkaufsfähigem Zustand portofrei bei LDE eingehen.
        Dieses Rückgaberecht besteht nicht bei Lieferungen von Audio- oder Videoaufzeichnungen (z.B. CD, DVD,
        Musik- oder Videokassetten) oder von Software, sofern der Kunde die versiegelte Verpackung geöffnet hat. Ebenso
        besteht kein Rückgaberecht beim Erwerb von Dateien zum Download, sobald der Download durch den Kunden erfolgt
        ist, da die einmal heruntergeladene Datei aufgrund ihrer Beschaffenheit nicht für eine Rücksendung geeignet ist.
      </p>

      <h3 class="s6">
        J) Rücksendekosten
      </h3>
      <p>Im Falle des Widerrufs oder der Rückgabe tragen Sie die Rücksendekosten.</p>
    </div>

    <div class="bloc">
      <h2 class="s4 light">
        3: Dienstleistungen
      </h2>
      <p>
        LDE bietet Schulen und anderen Bildungsträgern Dienstleistungen im Bereich des Etikettier- und Einbandservices,
        auch unter Anbindung an die Software LITTERA, der Lernmittelverwaltung mit CRISTAL, sowie der Verwaltung
        digitaler Schulbuchlizenzen. Eine etwaige Datenverarbeitung durch LDE für die jeweiligen Kunden erfolgt im
        Rahmen eines jeweils zu schließenden Auftragsverarbeitungsvertrags unter Verwendung der vom Kunden
        bereitgestellten Daten.
      </p>

      <h3 class="s6">
        A) Lizenzen
      </h3>
      <p>
        Soweit den Kunden die Nutzung von Software der LDE ermöglicht wird, erteilt LDE dem jeweiligen Kunden für die
        jeweils vereinbarte Nutzung eine nichtausschließlich und nicht übertragbare, auf die Dauer des Vertrags
        befristete, Lizenz.
      </p>

      <h3 class="s6">
        B) Verantwortung für Daten, Freistellung
      </h3>
      <p>
        LDE übernimmt keine Verantwortung für die Richtigkeit der vom Kunden eingestellten bzw. LDE von Kunden zur
        Verarbeitung übermittelten Daten. Der Kunde stellt LDE von allen Ansprüchen frei, die Dritte wegen der
        Verletzung ihrer Rechte durch vertragsgemäße Verarbeitung der vom Kunden eingegebene oder übermittelte Daten
        gegen LDE geltend machen.
      </p>

      <h3 class="s6">
        C) Datenschutz
      </h3>
      <p>
        Soweit LDE im Wege der Auftragsverarbeitung der vom Kunden eingegebenen oder übermittelten Daten tätig wird,
        gilt neben der Datenschutzerklärung von LDE auch die Datenschutzerklärung des Kunden.
      </p>

      <h3 class="s6">
        D) Technische Beschränkungen, Nutzungsvoraussetzungen
      </h3>
      <p>
        Die Nutzung digitaler Dienstleistungen kann von der Verwendung bestimmter Geräte und/oder Software und/oder
        technischen Mindestanforderungen an die zu verwendenden Geräte und Programme abhängen. Diese
        Nutzungsvoraussetzungen und etwaige LDE bekannte technische Beschränkungen entnehmen Sie bitte der Beschreibung
        der jeweiligen Dienstleistung.
      </p>

      <h3 class="s6">
        E) Verfügbarkeit, Wartung
      </h3>
      <p>
        1) LDE ist bestrebt, die digitalen Dienstleistungen jederzeit zur Verfügung zu stellen. Da dies jedoch aus
        technischen Gründen nicht immer möglich ist, gilt die Leistung von LDE als erbracht, wenn die Internetseiten
        bzw. Datenbanken im Jahresdurchschnitt zu 98 % für die Nutzung digitaler Inhalte, die Einstellung und den Abruf
        von Daten zur Verfügung stehen.
      </p>
      <p>
        2) In Fällen höherer Gewalt ist LDE von der Leistungspflicht befreit. Als höhere Gewalt gelten alle
        unvorhergesehenen Ereignisse sowie solche Ereignisse, deren Auswirkungen auf die Vertragserfüllung von keiner
        Partei zu vertreten sind. Ansprüche ergeben sich für den Kunden bei nicht durch von LDE zu verantwortenden
        Ausfällen nicht.
      </p>
      <p>
        3) Soweit Wartungsarbeiten erforderlich sind, werden diese nach 22 Uhr durchgeführt, sofern die
        Aufrechterhaltung des Betriebs keine frühere Wartung erfordert.
      </p>
    </div>

    <div class="bloc">
      <h2 class="s4 light">
        4 Schülerfirmen
      </h2>
      <p>Diese AGB gelten auch für Bestellungen von Verbrauchern, die von Schülerfirmen vermittelt werden.</p>
    </div>

    <div class="bloc">
      <h2 class="s4 light">
        5 Hinweise
      </h2>

      <h3 class="s6">
        A) Hinweise nach Art. 246, 246a, 246c EGBGB
      </h3>
      <p>Für Verbraucherverträge beachten Sie bitte die folgenden Hinweise:</p>
      <p>
        1) Die wesentlichen Eigenschaften der Waren oder Dienstleistungen können Sie den jeweiligen Angeboten und
        Beschreibungen entnehmen.
      </p>
      <p>2) Identität des Anbieters:</p>
      <address>
        LDE GmbH & Co.KG<br />
        Robert-Koch-Straße 35<br />
        77694 Kehl<br />
        Handelsregister: AG Freiburg HRA 370799
      </address>
      <p>vertreten durch</p>
      <address>
        LDE Verwaltungs GmbH<br />
        Robert-Koch-Straße 35<br />
        77694 Kehl<br />
        Handelsregister: AG Freiburg HRB 372026
      </address>
      <address>
        Geschäftsführer: Frédéric FRITSCH<br />
        Tel.: +49 (0)7851 48599-0<br />
        Fax +49 (0)7851 48599-22<br />
        E-Mail: <a :href="`mailto:${mailLdeDe}`">{{ mailLdeDe }}</a>
      </address>

      <p>
        3) Die bei den einzelnen Waren/Dienstleistungen angegebenen Preise sind Endpreise einschließlich der
        gesetzlichen Umsatzsteuer. Versandkosten entstehen nicht.
      </p>
      <p>
        4) Die Zahlungs- und Lieferbedingungen finden Sie oben unter § 2.5 und § 2.3. Soweit Vorauszahlung vereinbart
        ist, beginnt die Lieferfrist mit dem Zeitpunkt der Zahlungsanweisung.
      </p>
      <p>5) Es gelten die gesetzlichen Gewährleistungsvorschriften.</p>
      <p>6) Die Funktionsweise digitaler Inhalte können Sie der jeweiligen Beschreibung entnehmen.</p>
      <p>
        7) Etwaige wesentliche Beschränkungen der Interoperabilität und der Kompatibilität digitaler Inhalte mit
        Hard- und Software finden Sie in der jeweiligen Beschreibung der Produkte bzw. Dienstleistungen.
      </p>
      <p>
        8) Das Widerrufsrecht sowie Hinweise dazu, wann kein gesetzliches Widerrufsrecht besteht bzw. wann ein
        gesetzliches Widerrufsrecht erlischt, finden Sie in der Widerrufsbelehrung unter § 2.8.
      </p>
      <p>
        9) Kosten für den Einsatz des für den Vertragsabschluss genutzten Fernkommunikationsmittels, die über die
        Kosten für die bloße Nutzung des Fernkommunikationsmittels hinausgehen, entstehen nicht.
      </p>
      <p>
        10) Der Vertragsschluss im elektronischen Rechtsverkehr erfolgt, indem Sie uns eine Bestellung per E-Mail
        übermitteln und wir diese Bestellung durch Übersendung einer Versandbestätigung annehmen. Sie können etwaige
        Eingabefehler dadurch erkennen und berichtigen, dass Sie Ihre Bestellung vor Versand der E-Mail an uns
        sorgfältig durchlesen und bei Bedarf korrigieren.
      </p>
      <p>
        11) Der vollständige Vertragstext wird von uns nicht gespeichert. Sie können die Vertragsdaten vor dem
        Absenden der Bestellung über Ihr E-Mail-Programm bzw. die für den E-Mail-Versand genutzte Internetseite Ihres
        E-Mail-Anbieters ausdrucken oder speichern. Nach Zugang der Bestellung senden wir Ihnen die Bestelldaten, die
        gesetzlich vorgeschriebenen Informationen und die Widerrufsbelehrung per E-Mail.
      </p>
      <p>12) Die Vertragssprache ist deutsch.</p>

      <h3 class="s6">
        B) Online-Streitschlichtung gemäß Art. 14 Abs. 1 ODR-VO
      </h3>
      <p>
        Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit, die Sie hier finden:
        <a
          href="https://ec.europa.eu/consumers/odr/"
          target="_blank"
          rel="noreferrer noopener"
        >
          https://ec.europa.eu/consumers/odr/
        </a>.
      </p>
      <p>
        Hinweis gemäß § 36 Verbraucherstreitbeilegungsgesetz (VSGB): LDE ist nicht zur Teilnahme an einem
        Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle im Sinne des VSBG verpflichtet und wird an
        einem solchen Streitbeilegungsverfahren nicht teilnehmen.
      </p>

      <h3 class="s6">
        C) Versandrisiko
      </h3>
      <p>
        Verbraucher tragen die Gefahr des zufälligen Untergangs und der zufälligen Verschlechterung der verkauften Sache
        während der Versendung erst mit der Übergabe der Ware an den Verbraucher. Dies gilt sowohl bei versichertem, als
        auch bei unversichertem Versand. Dies gilt jedoch nicht, wenn der Verbraucher ein vorher nicht von LDE benanntes
        Transportunternehmen oder eine sonst zur Ausführung der Versendung bestimmte Person beauftragt hat.
      </p>
    </div>

    <div class="bloc">
      <h2 class="s4 light">
        6 Haftungsbeschränkung
      </h2>
      <p>
        A) Gegenüber Kunden, die keine Verbraucher sind, haftet LDE für Schäden, außer im Fall der Verletzung
        wesentlicher Vertragspflichten, nur, wenn und soweit LDE, seinen gesetzlichen Vertretern, leitenden Angestellten
        oder sonstigen Erfüllungsgehilfen Vorsatz oder grobe Fahrlässigkeit zur Last fällt. Im Fall der Verletzung
        wesentlicher Vertragspflichten haftet LDE für jedes schuldhafte Verhalten seiner gesetzlichen Vertreter,
        leitender Angestellter oder sonstiger Erfüllungsgehilfen.
      </p>
      <p>
        B) Außer bei Vorsatz oder grober Fahrlässigkeit gesetzlicher Vertreter, leitender Angestellter oder sonstiger
        Erfüllungsgehilfen, ist die Haftung von LDE der Höhe nach auf die bei Vertragsschluss typischerweise
        vorhersehbaren Schäden begrenzt.
      </p>
      <p>
        C) Eine Haftung für den Ersatz mittelbarer Schäden, insbesondere für entgangenen Gewinn, besteht nur bei
        Vorsatz oder grober Fahrlässigkeit gesetzlicher Vertreter, leitender Angestellter oder sonstiger
        Erfüllungsgehilfen von LDE.
      </p>
      <p>
        D) Die vorgenannten Haftungsausschlüsse und Beschränkungen gelten nicht für Schäden aus der Verletzung des
        Lebens, des Körpers oder der Gesundheit sowie im Fall zwingender gesetzlicher Regelungen.
      </p>
    </div>

    <div class="bloc">
      <h2 class="s4 light">
        7 Anwendbares Recht und Gerichtsstand
      </h2>
      <p>
        A) Für das Vertragsverhältnis gilt ausschließlich das Recht der Bundesrepublik Deutschland. Die Anwendung des
        UN-Übereinkommens über Verträge über den Internationalen Warenverkauf vom 11. April 1998 ist ausgeschlossen.
      </p>
      <p>
        B) Erfüllungsort ist der Sitz der Gesellschaft. Als Gerichtsstand wird 77694 Kehl vereinbart, sofern es sich
        bei dem Kunden oder Nutzer der von LDE angebotenen Dienstleistungen um einen Kaufmann im Sinne des
        Handelsgesetzbuches, eine juristische Person des öffentlichen Rechts oder ein öffentlich-rechtliches
        Sondervermögen handelt. Gleiches gilt, wenn der Kunde/Nutzer seinen Wohnsitz nach Vertragsschluss ins Ausland
        verlegt oder keinen allgemeinen Gerichtsstand in Deutschland hat.
      </p>
    </div>

    <div class="bloc">
      <h2 class="s4 light">
        8 Nichteinbeziehung und Unwirksamkeit
      </h2>
      <p>
        Für den Fall der ganz oder teilweisen Nichteinbeziehung oder Unwirksamkeit dieser AGB gelten die gesetzlichen
        Bestimmungen des § 306 BGB.
      </p>
    </div>
  </div>
</template>

<script>
import config from "@/config";

/**
 * Affiche la page des CGU de Cristal Web en allemand.
 */
export default {
  name: "CguCristalWebDe",
  computed: {
    urlExtranetDe() {
      return config.urlDe.extranet;
    },
    mailLdeDe() {
      return config.mailsDe.lde;
    },
  },
};
</script>

<style lang="scss"></style>

<template>
  <div id="cgu_poplab">
    <CguCristalWebDe v-if="country === 'de'" />
    <CguCristalWebFr v-else />
  </div>
</template>

<script>
import CguCristalWebFr from "@/components/legal/cw/CguCristalWebFr.vue";
import CguCristalWebDe from "@/components/legal/cw/CguCristalWebDe.vue";

import { mapGetters } from "vuex";

/**
 * Affiche les CGU de Cristal Web dans la bonne langue.
 */
export default {
  name: "CguCristalWeb",
  components: {
    CguCristalWebFr,
    CguCristalWebDe,
  },
  computed: {
    ...mapGetters(["country"]),
  },
};
</script>

<style lang="scss">
@use "@/assets/styles/views/_legal.scss";
</style>
